import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { useAllStores } from '../stores/useAllStores'

Vue.use(PiniaVuePlugin)
Vue.use(createPinia())

const ClientRouter = new VueRouter({
  mode: process.env.VUE_APP_ENTRYPOINT === 'admin' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'UofT Index',
      component: Home
    },
    {
      path: '/tree',
      name: 'Course Tree | UofT Index',
      component: () => import('../views/CourseTree.vue')
    },
    {
      path: '/directory',
      name: 'Directory | UofT Index',
      component: () => import('../views/Directory.vue')
    },
    {
      path: '/courses',
      name: 'Course Info | UofT Index',
      component: () => import('../views/CourseInfo.vue')
    },
    {
      path: '/professors',
      name: 'Professor | UofT Index',
      component: () => import('../views/ProfessorInfo.vue')
    },
    {
      path: '/droprate',
      name: 'Drop Rate | UofT Index',
      component: () => import('../views/DropRate.vue')
    },
    {
      path: '/timetable',
      name: 'Timetable | UofT Index',
      component: () => import('../views/Timetable.vue')
    },
    {
      path: '/session',
      name: 'Session | UofT Index',
      component: () => import('../views/Session.vue')
    },
    {
      path: '/dashboard',
      name: 'My Dashboard | UofT Index',
      component: () => import('../views/Dashboard.vue')
    },
    {
      path: '/feedback',
      name: 'Feedback | UofT Index',
      component: () => import('../views/Feedback.vue')
    },
    {
      path: '/changelog',
      name: 'Changelog | UofT Index',
      component: () => import('../views/Changelog.vue')
    },
    {
      path: '/disclaimers',
      name: 'Disclaimers | UofT Index',
      component: () => import('../views/Disclaimer.vue')
    },
    {
      path: '/privacy',
      name: 'Privacy Policy | UofT Index',
      component: () => import('../views/Privacy.vue')
    },
    {
      path: '/terms',
      name: 'Terms of Use | UofT Index',
      component: () => import('../views/Terms.vue')
    },
    {
      path: '/compare',
      name: 'Compare Courses | UofT Index',
      component: () => import('../views/Compare.vue')
    },
    {
      path: '/about',
      name: 'About Us | UofT Index',
      component: () => import('../views/About.vue')
    },
    {
      path: '/plan',
      name: 'Course Planner | UofT Index',
      component: () => import('../views/Planner.vue')
    },
    {
      path: '/c/:course',
      redirect: to => {
        return { path: '/courses', query: { c: to.params.course } }
      }
    },
    {
      path: '/t/:timetable',
      redirect: to => {
        return { path: '/timetable', query: { id: to.params.timetable } }
      }
    },
    {
      path: '*',
      redirect: '/home'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

const routeMap = {
  courses: 'Directory',
  directory: 'Directory',
  compare: 'Compare',
  tree: 'Course Tree',
  droprate: 'Drop Rates',
  professors: 'Directory',
  plan: 'Planner'
}

ClientRouter.afterEach((to, from) => {
  const store = useAllStores()

  // Update the breadcrumbs
  if (to.path === '/professors' && from.path === '/courses') {
    store.app.pageCrumbs.push({ text: store.data.selectedProf.displayName, href: to.fullPath })
  } else {
    store.app.pageCrumbs = [{ text: 'Home', href: '/' }]
    ClientRouter.currentRoute.fullPath.split('/').slice(1).forEach((page) => {
      let crumb
      let pageText = page
      const queryIdx = page.indexOf('?')

      if (queryIdx > -1) pageText = page.slice(0, queryIdx)
      if (['courses', 'professors'].includes(pageText)) {
        // If direct navigation or going from course->course insert directory crumb
        const prevPage = ['/directory', '/tree', '/compare'].includes(from.path) ? from.fullPath : '/directory'
        // If from compare or prereq with query we need to retain the query values
        pageText = prevPage.indexOf('?') > -1 ? prevPage.slice(1, prevPage.indexOf('?')) : prevPage.slice(1)
        store.app.pageCrumbs.push({ text: routeMap[pageText.toLowerCase()] || 'Directory', href: prevPage })
        crumb = { text: decodeURI(page.slice(queryIdx + 3)), href: '/' + page }
      } else {
        crumb = { text: routeMap[pageText] || pageText, href: '/' + page.toLowerCase() }
      }
      store.app.pageCrumbs.push(crumb)
    })
  }

  // Catch duplicate nav exception
  ['push', 'replace'].forEach(method => {
    const originalMethod = VueRouter.prototype[method]
    VueRouter.prototype[method] = function m (location) {
      return originalMethod.call(this, location).catch(() => {})
    }
  })

  // Change the tab title based on what page we are on
  if (to.path === '/courses' && to.query.c) {
    document.title = to.query.c + ' | UofT Index'
  } else {
    document.title = to.name
  }
})

export default ClientRouter
