<template>
  <v-snackbar v-model="updates" color="#009FFF" class="pa-4" max-width="295" :min-width="!store.app.onMobile ? 'initial' : ''" elevation="12"
              :bottom="this.store.app.onMobile" :left="!this.store.app.onMobile" timeout="-1">
    <v-col class="px-0">
      <v-row class="mb-1 px-3" align="center">
        <v-icon color="#FFFFFF" class="mr-2" size="28">mdi-book-open-page-variant</v-icon>
        <p class="text-h6 font-weight-bold white--text mb-0">See What's New!</p>
      </v-row>
      <p class="text-body-1 mb-0 white--text">
        Checkout the latest changes and new stuff in <strong>v{{ store.app.latestVersion }}</strong>
      </p>
      <v-row class="mt-2 px-2">
        <v-spacer/>
        <v-btn text class="mr-2 py-4" small @click="sawUpdate(false)">Dismiss</v-btn>
        <v-btn color="#000000" class="py-4" small depressed @click="sawUpdate(true)">View Details</v-btn>
      </v-row>
    </v-col>
  </v-snackbar>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'UpdateModal',
  props: {
    showUpdates: { type: Boolean, required: true }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    updates: false
  }),
  mounted () {
    this.updates = this.showUpdates
  },
  beforeDestroy () {
    if (this.updates) this.sawUpdate(false)
  },
  watch: {
    updates: function (newValue) {
      if (!newValue) this.sawUpdate()
    }
  },
  methods: {
    sawUpdate (viewChangelog) {
      localStorage.setItem('latest-version', this.store.app.latestVersion)
      this.store.app.latestUpdate = true
      this.updates = false
      if (viewChangelog) {
        this.$router.push('/changelog')
        this.$gtag.event('viewed_update', { value: 1 })
      }
    }
  }
}
</script>

<style scoped>

</style>
