import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: { customProperties: true, variations: false },
    themes: {
      light: {
        text: '#000000',
        secondary: '#6D6D6D',
        accent: '#003C85',
        error: '#FF0000',
        success: '#04DB8E',
        border: '#F2F2F2',
        tooltip: '#6D6D6D',
        background: '#FFFFFF',
        component: '#FFFFFF',
        red: '#FFC6C6',
        redText: '#ED0037',
        orange: '#FFDBB0',
        orangeText: '#C46E02',
        blue: '#B6DDFF',
        blueText: '#1976D2',
        green: '#B3FFB8',
        greenText: '#3D9941',
        grey: '#DBDBDB',
        greyText: '#686666',
        purple: '#DBBCFB',
        purpleText: '#8810CC',
        mint: '#B5FBE4',
        mintText: '#338A66',
        pink: '#FDCFF2',
        pinkText: '#C81BBD',
        brown: '#E8CBAF',
        brownText: '#6F5454',
        lilac: '#c5cdf9',
        lilacText: '#4d518c',
        yellow: '#FFECA4',
        yellowText: '#958116',
        utsc: '#A6DAFF',
        utscText: '#295D92',
        utsg: '#FFA2C5',
        utsgText: '#AA2548',
        utm: '#9DFFA3',
        utmText: '#208B26'
      },
      dark: {
        text: '#FFFFFF',
        secondary: '#EEEEEE',
        accent: '#1976D2',
        error: '#BF1537',
        success: '#06BE7C',
        border: '#272727',
        tooltip: '#404040',
        background: '#0F0F0F',
        component: '#1E1E1E',
        red: '#D25276',
        redText: '#510B1D',
        orange: '#EABB83',
        orangeText: '#8E540C',
        blue: '#7DB9EE',
        blueText: '#075EB5',
        green: '#7FC683',
        greenText: '#206A24',
        grey: '#DBDBDB',
        greyText: '#686666',
        purple: '#BD93E8',
        purpleText: '#720CAC',
        mint: '#9AD9C4',
        mintText: '#236449',
        pink: '#DD9DCE',
        pinkText: '#86137F',
        brown: '#D7AE87',
        brownText: '#4F4040',
        lilac: '#c5cdf9',
        lilacText: '#4d518c',
        yellow: '#EAD582',
        yellowText: '#5C511A',
        utsc: '#73A4D7',
        utscText: '#1B4066',
        utsg: '#BD5975',
        utsgText: '#590C20',
        utm: '#67BF6C',
        utmText: '#0F5B13'
      }
    }
  }
})
