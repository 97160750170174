import Vue from 'vue'
import router from './router'
import vuetify from './plugins/vuetify'
import scroll from 'vue-scrollto'
import { initializeApp } from 'firebase/app'
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth'
import VueGtag from 'vue-gtag'
import VueApexCharts from 'vue-apexcharts'
import vuePositionSticky from 'vue-position-sticky'
import Toast, { TYPE, POSITION } from 'vue-toastification'
import { required, email, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import { parseCookie } from './utils/shared/helpers'

/**
 * Import styles here. Ensure main.css is loaded last
 * so we can override any third-party styles.
 */
import 'vue-toastification/dist/index.css'
import '@/main.css'
import { useAllStores } from './stores/useAllStores'

const App = () => import('./App.vue')
const Admin = () => import('./Admin.vue')

Vue.config.productionTip = false

Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.use(scroll)
// Vue.use(PiniaVuePlugin)
Vue.use(VueApexCharts)
Vue.use(vuePositionSticky)
Vue.component('apexchart', VueApexCharts)

setInteractionMode('lazy')
extend('required', { ...required, message: '{_field_} cannot be empty' })
extend('min', { ...min, message: '{_field_} may not be less than {length} characters' })
extend('email', { ...email, message: 'Email must be valid' })

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

/**
 * Configure snackbar notifications.
 * https://github.com/Maronato/vue-toastification/
 */
Vue.use(Toast, {
  closeOnClick: false,
  draggable: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
  transitionDuration: 500,
  position: POSITION.BOTTOM_CENTER,
  maxToasts: 3,

  toastDefaults: {
    [TYPE.ERROR]: { closeButton: false }
  }
})

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_FIREBASE_GTAG_ID, params: { cookie_flags: 'domain=uoftindex.ca;samesite=none;secure' } }
}, router)

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT,
  storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}
// Initialize Firebase
initializeApp(firebaseConfig)

// As httpOnly cookies are to be used, do not persist any state client side.
const auth = getAuth()
setPersistence(auth, browserSessionPersistence).then(function () {})

if (process.env.NODE_ENV !== 'development') {
  // Error handling
  Bugsnag.start({
    apiKey: '977b60e956bad3df70b6d290f72711a2',
    plugins: [new BugsnagPluginVue()]
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
}

/** Multi-project support. */
const BUILD_TARGET = {
  client: App,
  admin: Admin
}

const Entrypoint = BUILD_TARGET[(process.env.VUE_APP_ENTRYPOINT || 'client')]

new Vue({
  router,
  vuetify,
  render: h => h(Entrypoint),
  created () {
    // This code runs first, make sure the store has what we need
    // since some components expect this data to be there.
    const userInfo = parseCookie(document.cookie).userInfo
    if (userInfo) useAllStores().user.setUserState(JSON.parse(userInfo))
  }
}).$mount('#app')
