import { defineStore } from 'pinia'
import { getAuth } from 'firebase/auth'
import Bugsnag from '@bugsnag/js'

export const appStore = defineStore('app', {
  state: () => ({
    cookieOk: false,
    welcomeModal: false,
    commandModal: false,
    latestUpdate: false,
    latestVersion: '4.2.0-beta',
    darkMode: false,
    onDesktop: true,
    onMobile: false,
    liveAnimations: true,
    customFABPath: ['/directory', '/tree', '/courses'],
    pageCrumbs: [],
    defaultSessionPage: 'LgEmail'
  }),
  actions: {
    setCookieOk () {
      this.cookieOk = true
    },
    setWelcomeModal (value) {
      this.welcomeModal = value
    },
    setLatestUpdate () {
      this.latestUpdate = true
    },
    setDarkTheme (value) {
      this.darkMode = value
    },
    updateBreadcrumbs (update) {
      const crumbIndex = this.pageCrumbs.findIndex(crumb => crumb.href.startsWith(update.page))
      this.pageCrumbs[crumbIndex].text = update.text
    },
    async verifyMe () {
      const currentUser = getAuth().currentUser

      try {
        await currentUser.sendEmailVerification()
        return 'Verification email sent! Logout and log back in after verifying your account'
      } catch (error) {
        Bugsnag.notify(error)
        throw new Error('An error occurred trying to send the verification email. Please try again later.')
      }
    }
  }
})
